$font-family-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family: 'RegularAimsunMedium', $font-family-fallback;
$text-color: #09254c;

body {
    margin: 0;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $text-color;
}

@font-face {
    font-family: 'RegularAimsunMedium';
    font-style: normal;
    src: url('./RegularAimsun-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'RegularAimsunBold';
    font-style: bold;
    src: url('./RegularAimsun-Bold.otf') format('opentype');
}
